<template>
  <v-container style="font-size: 24px">
    <template v-for="(keyProblem, i) in triz.keyProblems">
      <v-row class="special_p" :key="`keyProblem-${i}-title`">
        <div class="delete_right">
          <strong>{{ parentid }}.{{ i + 1 }}.关键问题{{ i + 1 }}</strong>
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeProblem(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </div>
      </v-row>
      <v-row :key="`keyProblem-${i}-head`">
        <p>
          {{ triz.problems }}
          对于问题
          <triz-select
            v-model="keyProblem.description"
            flat
            :items="
              [
                ...triz.relationGraphData.links
                  .filter((link) => !link.isHide)
                  .filter(
                    (link) =>
                      link.lineShape == lineShapes.bad ||
                      link.lineShape == lineShapes.unsatisfy ||
                      link.lineShape == lineShapes.over
                  )
                  .map((ele) => ({ problem: ele.issue })),
                ...flatTreeData(triz.reasonTreeData ? triz.reasonTreeData : {}),
                ...triz.relationGraphData.links.filter((link) => link.rule),
              ].filter((item) => item.problem).map((item) => item.problem)
            "
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
          。下面使用矛盾创新原理工具进行分析。
        </p>
      </v-row>
      <v-container :key="`keyProblem-${i}-detail`">
        <template v-for="(conflict, j) in keyProblem.conflicts">
          <template v-if="conflict.type == 'technical'">
            <v-row :key="`keyProblem-${i}-mark`">
              <strong>{{ parentid }}.{{ i + 1 }}.{{ j + 1 }}-使用技术矛盾解决问题</strong>
              <v-btn
                v-show="!locked"
                dense
                class="ma-2"
                tile
                outlined
                color="error"
                @click="removeConflict(keyProblem, j)"
              >
                <v-icon left>mdi-minus</v-icon> 删除
              </v-btn>
            </v-row>
            <Technical
              :key="`tech-keyProblem-${i}-technical-${j}`"
              :triz="triz"
              :conflict="conflict"
              :parentid="``"
              @change="change"
            ></Technical>
          </template>
          <template v-if="conflict.type == 'physical'">
            <v-row :key="`keyProblem`">
              <strong>{{ parentid }}.{{ i + 1 }}.{{ j + 1 }}-使用物理矛盾解决问题</strong>
              <v-btn
                v-show="!locked"
                dense
                class="ma-2"
                tile
                outlined
                color="error"
                @click="removeConflict(keyProblem, j)"
              >
                <v-icon left>mdi-minus</v-icon> 删除
              </v-btn>
            </v-row>
            <Physical
              :key="`keyProblem-${i}-physical-${j}`"
              :conflicts="conflict.technicalConflicts"
              :triz="triz"
              :conflict="conflict"
              @change="change"
            ></Physical>
          </template>
        </template>
      </v-container>
      <v-row class="special_p" :key="`keyProblem-${i}-add`">
        <p>
          <v-btn
            v-show="!locked"
            dense
            class="ma-2"
            tile
            outlined
            color="success"
            @click="addConflict(keyProblem, 'technical')"
          >
            <v-icon left>mdi-plus</v-icon> 使用技术矛盾解决
          </v-btn>
          <v-btn
            v-show="!locked"
            dense
            class="ma-2"
            tile
            outlined
            color="success"
            @click="addConflict(keyProblem, 'physical')"
          >
            <v-icon left>mdi-plus</v-icon> 使用物理矛盾解决
          </v-btn>
        </p>
      </v-row>
    </template>
    <v-row class="special_p">
      <p>
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addProblem()">
          <v-icon left>mdi-plus</v-icon> 添加关键问题
        </v-btn>
      </p>
    </v-row>
  </v-container>
</template>

<script>
import Physical from '@/views/triz/component/Physical'
import Technical from '@/views/triz/component/Technical'
import { lineShapes } from '../../../store/default'

export default {
  components: {
    Physical,
    Technical,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
    parentid: {
      type: String,
      default: '',
    }
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      lineShapes,
      // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    addProblem() {
      console.log('addProblem')
      if (!this.triz.keyProblems) {
        this.triz.keyProblems = []
      }
      this.triz.keyProblems.push({
        description: '',
        conflicts: [],
      })
      this.change()
    },
    removeProblem(index) {
      const keyProblem = this.triz.keyProblems[index]
      keyProblem.conflicts.forEach((conflict) => {
        conflict.solutions.forEach((solution) => {
          this.triz.solutionScores.splice(
            this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
            1
          )
        })
      })
      this.triz.keyProblems.splice(index, 1)
      this.change()
    },
    addConflict(keyProblem, type) {
      console.log('addConflict')
      if (!keyProblem.conflicts) {
        keyProblem.conflicts = []
      }
      keyProblem.conflicts.push({
        id: 'conflict-' + new Date().getTime(),
        type,
        isPhysical: false,
        physicalConflicts: [],
        technicalConflicts: [
          {
            c_if: '',
            c_then: '',
            but: '',
            good: [],
            bad: [],
          },
          {
            c_if: '',
            c_then: '',
            but: '',
            good: [],
            bad: [],
          },
        ],
        selectedConflict: 0,
        principles: [],
        solutions: [],
        selectedPrinciple: '',
        slots: {
          slot01: '',
          slot02: '',
          slot03: '',
          slot04: '',
          slot05: '',
        },
      })
      keyProblem.conflicts.sort((a, b) => a.type > b.type)
      this.change()
    },
    removeConflict(keyProblem, j) {
      const conflict = keyProblem.conflicts[j]
      conflict.solutions.forEach((solution) => {
        this.triz.solutionScores.splice(
          this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
          1
        )
      })
      keyProblem.conflicts.splice(j, 1)
      this.change()
    },
    flatTreeData(tree) {
      const result = []
      if (!tree.children) return []
      this.flatTree(tree.children, result)
      return result.filter((ele) => ele.selected)
    },
    flatTree(children, result) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        result.push(child)
        if (child.children) {
          this.flatTree(child.children, result)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.principle_size2 {
  p {
    text-indent: 0 !important;
  }
}
.principle_size {
  margin-top: 0 !important;
  p {
    font-size: 18px !important;
    text-indent: 30px !important;
    margin-bottom: 0px !important;
  }
}
.margin_select {
  margin: 0 10px;
}
// .delete_right{
//   display: flex;
//   position: relative;
//   width: 100%;
//   .v-btn{
//     position: absolute;
//     right: 0;
//     margin-top: 0px!important;
//   }
// }
.Triz_Select_fujia {
  line-height: 46px !important;
  .v-select__selection {
    text-indent: 10px;
  }
}
</style>
