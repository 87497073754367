<template>
  <v-container style="font-size: 24px">
    <v-row>
      <p>（2） 相互作用矩阵：根据上述功能结构分析，创建相互作用矩阵如下表所示。</p>
    </v-row>
    <v-row justify="center" align="center">
      <triz-validator rules="relation" id="功能分析-相互作用矩阵">
        <RelationTable
          id="relation-table"
          :items="[...triz.relationGraphData.nodes]"
          v-model="triz.relations"
          @relation-changed="relationChange"
          @related="addRelation"
          @unrelated="removeRelation"
          :disabled="locked"
          style="margin-right: 12px"
        />
      </triz-validator>
    </v-row>
    <v-row align="center" justify="center"> 表 相互作用矩阵表 </v-row>
    <v-row>
      <p>
        根据上述元件列表分析各元件之间的相互作用，如两者有相互作用关系，则以“+”标记，后续需要分析具体作用是什么，否则以“-”标记，后续分析中不再考虑，创建相互作用矩阵如上表所示。
      </p>
    </v-row>
  </v-container>
</template>

<script>
import RelationTable from '@/components/RelationTable.vue'
import FunctionTable from '@/components/FunctionTable.vue'
import InnerTextField from '@/components/InnerTextField'
import RelationGraph from '@/components/RelationGraph'
// import ScoreTable from '@/components/ScoreTable'
import IssueTable from '@/components/IssueTable'

export default {
  components: {
    RelationTable,
    InnerTextField,
    RelationGraph,
    FunctionTable,
    // ScoreTable,
    IssueTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    relationGraphData() {
      return this.triz.relationGraphData
    },
  },
  watch: {
    relationGraphData(data) {
      data.nodes.forEach((node) => {
        node.flated = false
      })
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(data)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(this.triz.relationGraphData)
    })
  },
  methods: {
    changeNodeText(node) {
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.changeNodeText(node)
    },
    addSystemComponents() {
      const nodes = this.triz.relationGraphData.nodes

      const id = `${new Date().getTime()}`
      const text = ''
      const item = { id, text, data: { comp: true } }

      nodes.push(item)
      this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
      this.triz.relations.forEach((ele) => {
        ele.push(0)
      })
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [item], links: [] })
    },
    addSuper() {
      const nodes = this.triz.relationGraphData.nodes

      const id = `${new Date().getTime()}`
      const text = ''
      const item = { id, text, data: { super: true } }

      nodes.push(item)
      this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
      this.triz.relations.forEach((ele) => {
        ele.push(0)
      })
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [item], links: [] })
    },
    removeItem(item) {
      const index = this.triz.relationGraphData.nodes.findIndex((ele) => ele.id === item.id)
      this.triz.relations.splice(index + 1, 1)
      this.triz.relations.forEach((ele) => {
        ele.splice(index + 1, 1)
      })
      this.triz.relationGraphData.nodes.splice(index, 1)
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.removeNodeById(item.id)
      this.triz.clips.forEach((clip) => {
        const index = clip.selected.findIndex((select) => select.id === item.id)
        if (index !== -1) {
          clip.selected.splice(index, 1)
        }
      })
    },
    relationChange() {
      this.$emit('change', this.triz)
    },
    addRelation(relation1, relation2) {
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [], links: [relation1, relation2] })
    },
    removeRelation(relation1, relation2) {
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.removeRelation(relation1)
      this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.removeRelation(relation2)
    },
    change(data) {
      this.$emit('change', this.triz)
    },
    graphDataChange(json) {
      this.triz.relationGraphData = json
      this.$emit('change', this.triz)
    },
  },
}
</script>
