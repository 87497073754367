<template>
  <v-container style="font-size: 24px">
    <v-row>
      <div class="mr-8">
        <triz-validator rules="treeRequired" id="因果链分析-因果树" class="Causal_tree">
          <ReasonTree id="reasion-tree" v-model="triz.reasonTreeData" @change="change" />
        </triz-validator>
      </div>
    </v-row>
    <v-row align="center" justify="center"> 图 系统的因果链关系图 </v-row>
    <p>根据项目实际情况确定关键缺点。将关键缺点转化为关键问题，并寻找可能的解决方案。从关键问题中挖掘可能存在的矛盾:</p>
    <v-row>
      <ReasonTable :list="flatTreeData(triz.reasonTreeData)" v-model="triz.reasons" @change="reasonChange" />
    </v-row>
    <v-row align="center" justify="center"> 表 因果关系表 </v-row>
    <v-row>
      <p>
        根据前文项目背景提到的，目前传统
        <triz-validator rules="required" id="因果链分析-文本框1">
          <InnerTextField @input="change" v-model="triz.reasonSlots.slot01" />
        </triz-validator>
        系统的主要缺陷，包括
        <triz-validator rules="required" id="因果链分析-文本框2">
          <InnerTextField @input="change" v-model="triz.reasonSlots.slot02" />
        </triz-validator>
        等，经因果链分析，选择关键缺点因主要有：
        <triz-validator rules="required" id="因果链分析-文本框3">
          {{ triz.reasons.map(ele => ele.label).join(',') }}
        </triz-validator>
        等。针对这些关键问题，后文将会尝试用TRIZ理论尝试解决。
      </p>
    </v-row>
  </v-container>
</template>

<script>
import ReasonTree from '@/components/ReasonTree.vue'
import InnerTextField from '@/components/InnerTextField'
import ReasonTable from '@/components/ReasonTable'

export default {
  components: {
    ReasonTree,
    InnerTextField,
    ReasonTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },

  mounted() {},
  methods: {
    change() {
      console.log('change')
      this.$emit('change', this.triz)
    },
    reasonChange(list) {
      console.log('reasonChange')
      this.triz.reasons = list
      this.change()
    },
    flatTreeData(tree) {
      const result = []
      if (!tree.children) return
      this.flatTree(tree.children, result)
      return result.filter((ele) => ele.selected)
    },
    flatTree(children, result) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        result.push(child)
        if (child.children) {
          this.flatTree(child.children, result)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.Causal_tree{
  .v-btn:not(.v-btn--round).v-size--default{
    min-width: 10px;
    padding: 18px;
    border-radius: 25px;
  }
}
</style>