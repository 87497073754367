<template>
  <div style="font-size: 24px">
    <triz-validator rules="required" id="试验验证内容与结果">
      <Tinymce @input="change" v-model="triz.experiment" placeholder="请输入试验验证内容与结果" />
    </triz-validator>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'

export default {
  name: 'nice',
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  components: {
    Tinymce,
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
