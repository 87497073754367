import { render, staticRenderFns } from "./1-2-2-Current.vue?vue&type=template&id=60512941"
import script from "./1-2-2-Current.vue?vue&type=script&lang=js"
export * from "./1-2-2-Current.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports