<template>
  <div style="font-size: 24px">
    <!-- <v-dialog v-model="searchDialog" max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="text-h5"></span>
          </v-card-title>
          <v-card-text>
            <iframe width="100%" height="600px" src="https://www.sogou.com"></iframe>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ccc darken-4" @click="searchDialog = false"> 关闭 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    <!-- <v-btn class="searchBtn" @click="showSearchDialog">搜索</v-btn> -->
    <template v-for="(search, i) in this.triz.searches">
      <v-container :key="`searches-${i}`">
        <v-row>
          <p>功能向导搜索{{ i + 1 }}</p>
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeSearch(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <v-row>
          <p>1.识别要解决的关键问题</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-识别要解决的关键问题">
          <Tinymce v-model="search.step1" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>2.明确要执行的特定功能</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-明确要执行的特定功能">
          <Tinymce v-model="search.step2" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>3.制定所需的参数</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-制定所需的参数">
          <Tinymce v-model="search.step3" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>4.将功能一般化</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-将功能一般化">
          <Tinymce v-model="search.step4" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>5.在相关和非相关行业，识别执行类似功能的其他技术</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-识别执行类似功能的其他技术">
          <Tinymce v-model="search.step5" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>6.基于项目需求和条件限制，选择最适合执行期望功能的技术</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-选择最适合执行期望功能的技术">
          <Tinymce v-model="search.step6" @input="change" placeholder="请输入" />
        </triz-validator>
        <v-row>
          <p>7.识别和解决适应和实施所选技术的次生问题</p>
        </v-row>
        <triz-validator rules="required" id="功能向导搜索（FOS）-识别和解决适应和实施所选技术的次生问题">
          <Tinymce v-model="search.step7" @input="change" placeholder="请输入" />
        </triz-validator>

        <template v-for="(solution, j) in search.solutions">
          <v-row :key="`selectedPrinciple-${j}`"> </v-row>
          <v-row :key="`delete-${j}`">
            <strong>基于功能向导的解决方案{{ j + 1 }}</strong>
            <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeSolution(search, j)">
              <v-icon left>mdi-minus</v-icon> 删除
            </v-btn>
          </v-row>
          <v-row :key="`detail-${j}`">
            <v-col style="padding: 12px 12px 12px 0">
              <triz-validator rules="required" id="技术矛盾-关键问题-解决方案说明">
                <Tinymce @input="change" v-model="solution.solutionDetail" placeholder="请输入解决方案" />
              </triz-validator>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addSolution(search)">
            <v-icon left>mdi-plus</v-icon> 添加解决方案
          </v-btn>
        </v-row>
      </v-container>
    </template>
    <v-row>
      <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addSearch">
        <v-icon left>mdi-plus</v-icon> 添加功能向导搜索
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InnerTextField from '@/components/InnerTextField'
import Tinymce from '@/components/Tinymce'

export default {
  components: {
    InnerTextField,
    Tinymce,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      searchDialog: false,
      // // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    showSearchDialog() {
      this.searchDialog = true
    },
    addSearch() {
      const id = `search-${new Date().getTime()}`
      this.triz.searches.push({
        id,
        solutions: [],
      })
      this.change()
    },
    removeSearch(i) {
      const search = this.triz.searches[i]
      search.solutions.forEach((solution) => {
        this.triz.solutionScores.splice(
          this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
          1
        )
      })
      this.triz.searches.splice(i, 1)
      this.change()
    },
    addSolution(search) {
      const id = `guide-${new Date().getTime()}`
      search.solutions.push({
        id,
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeSolution(search, i) {
      const [{ id }] = search.solutions.splice(i, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
  },
}
</script>
<style lang="scss">
.searchBtn {
  margin-bottom: 10px;
}
</style>
