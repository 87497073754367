<template>
  <v-container style="font-size: 24px">
    <p style="text-indent: 0">1、功能结构分析</p>
    <p>（1）确定系统、组件、超系统分析。</p>
    <p>
      界定问题系统边界：
      <triz-validator rules="required" id="功能分析-问题系统边界">
        <InnerTextField @input="change" v-model="triz.functionSlots.slot01" />
      </triz-validator>
    </p>
    <p>
      明确问题系统功能：
      <triz-validator rules="required" id="功能分析-问题系统功能">
        <InnerTextField @input="change" v-model="triz.functionSlots.slot02" />
      </triz-validator>
    </p>
    <p>
      确定问题系统产品：
      <triz-validator rules="required" id="功能分析-问题系统产品">
        <InnerTextField @input="change" v-model="triz.functionSlots.slot03" />
      </triz-validator>
    </p>
    <p style="text-indent: 0">根据系统目标和约束选择合适的系统边界，完成系统和超系统组件分析：</p>
    <v-row id="system-components" align="center" justify="center" class="FunctionalAnalysis">
      <v-col align-self="start" style="padding-left: 0">
        <v-card color="#fff" height="378" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
          <div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%">
            <v-row class="d-flex">
              <v-col cols="9">工程系统</v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <triz-validator rules="required" id="功能分析-工程系统" style="font-size: 18px">
                {{ triz.functionSlots.slot03 }}
              </triz-validator>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col align-self="start">
        <v-card color="#fff" min-height="378" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
          <div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%">
            <v-row class="d-flex">
              <v-col cols="8">系统组件</v-col>
              <v-col cols="2">
                <v-btn v-show="!locked" small color="green" @click="addSystemComponents" outlined>
                  <v-icon color="green">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-list class="list">
            <v-list-item v-for="(item, i) in triz.relationGraphData.nodes.filter((ele) => ele.data.comp)" :key="i">
              <triz-validator rules="required" id="功能分析-系统组件" style="font-size: 18px">
                <triz-textarea :max="11" class="Triz_textarea_system" v-model="item.text" @input="changeNodeText(item)">
                </triz-textarea>
              </triz-validator>
              <v-btn
                v-show="!locked && triz.relationGraphData.nodes.filter((ele) => ele.data.comp).length > 1"
                small
                color="red"
                @click="removeItem(item)"
                outlined
              >
                <v-icon color="red">mdi-minus</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col align-self="start">
        <v-card color="#fff" min-height="378" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
          <div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%">
            <v-row class="d-flex">
              <v-col cols="8">超系统组件</v-col>
              <v-col cols="2">
                <v-btn
                  v-show="!locked && triz.relationGraphData.nodes.filter((ele) => ele.data.super).length < 8"
                  small
                  color="green"
                  @click="addSuper"
                  outlined
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-list>
            <v-list-item v-for="(item, i) in triz.relationGraphData.nodes.filter((ele) => ele.data.super)" :key="i">
              <triz-validator rules="required" id="功能分析-超系统组件" style="font-size: 18px">
                <triz-textarea
                  :max="11"
                  class="Triz_textarea_system"
                  v-model="item.text"
                  @input="changeNodeText(item)"
                  :placeholder="i == 0 ? '【请输入目标组件】' : '【请输入】'"
                >
                </triz-textarea>
              </triz-validator>
              <v-btn v-show="!locked" small color="red" @click="removeItem(item)" outlined>
                <v-icon color="red">mdi-minus</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center"> 图 系统的组件 </v-row>
  </v-container>
</template>

<script>
import RelationTable from '@/components/RelationTable.vue'
import FunctionTable from '@/components/FunctionTable.vue'
import InnerTextField from '@/components/InnerTextField'
import RelationGraph from '@/components/RelationGraph'
// import ScoreTable from '@/components/ScoreTable'
import IssueTable from '@/components/IssueTable'

export default {
  components: {
    RelationTable,
    InnerTextField,
    RelationGraph,
    FunctionTable,
    // ScoreTable,
    IssueTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    relationGraphData() {
      return this.triz.relationGraphData
    },
  },
  watch: {
    relationGraphData(data) {
      data.nodes.forEach((node) => {
        node.flated = false
      })
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.setJsonData(data)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.setJsonData(this.triz.relationGraphData)
    })
  },
  methods: {
    changeNodeText(node) {
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.changeNodeText(node)
    },
    addSystemComponents() {
      const nodes = this.triz.relationGraphData.nodes

      const id = `${new Date().getTime()}`
      const text = ''
      const item = { id, text, data: { comp: true } }

      nodes.push(item)
      this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
      this.triz.relations.forEach((ele) => {
        ele.push(0)
      })
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [item], links: [] })
    },
    addSuper() {
      const nodes = this.triz.relationGraphData.nodes

      const id = `${new Date().getTime()}`
      const text = ''
      const item = { id, text, data: { super: true } }

      nodes.push(item)
      this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
      this.triz.relations.forEach((ele) => {
        ele.push(0)
      })
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [item], links: [] })
    },
    removeItem(item) {
      const index = this.triz.relationGraphData.nodes.findIndex((ele) => ele.id === item.id)
      this.triz.relations.splice(index + 1, 1)
      this.triz.relations.forEach((ele) => {
        ele.splice(index + 1, 1)
      })
      this.triz.relationGraphData.nodes.splice(index, 1)
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.removeNodeById(item.id)
      this.triz.clips.forEach((clip) => {
        const index = clip.selected.findIndex((select) => select.id === item.id)
        if (index !== -1) {
          clip.selected.splice(index, 1)
        }
      })
    },
    relationChange(relation) {
      this.$emit('change', this.triz)
    },
    addRelation(relation) {
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.appendJsonData({ nodes: [], links: [relation] })
    },
    removeRelation(relation) {
      this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.removeRelation(relation)
    },
    change(data) {
      this.$emit('change', this.triz)
    },
    graphDataChange(json) {
      this.triz.relationGraphData = json
      this.$emit('change', this.triz)
    },
  },
}
</script>
