<template>
  <div style="font-size: 24px">
    <p>
      经典TRIZ理论中有八大技术系统进化法则，分别是<strong>技术系统完备性法则、能量传递法则、动态性进化法则、提高理想度法则、子系统不均衡进化法则、向超系统进化法则、向微观级进化法则以及协调性法则</strong>。这些法则集中体现了产品在实现功能过程中改进和发展的趋势。
    </p>
    <p>
      针对传统
      <triz-validator rules="required" id="八大进化法则-系统名称">
        <InnerTextField @input="change" v-model="triz.system" />
      </triz-validator>
      系统做进化分析，部分进化法则对装置进化有了一些明确要求，结果如下。
    </p>
    <p>
      <v-select
        v-show="!locked"
        v-model="triz.evaluation.selectedEvaluations"
        :items="evaluations"
        @change="change"
        multiple
        solo
        small-chips
        deletable-chips
        placeholder="请选择进化法则"
        color="success"
        class="jhfz_padding"
      >
      </v-select>
    </p>
    <template v-if="isSelected(1)">
      <p><strong>提高理想度法则</strong>：理想度应保持增长，不可能降低。</p>
      <v-row align="center" justify="center">
        <img id="evaluation-expression" src="/expression.svg" />
      </v-row>
      <v-row align="center" justify="center">
        <div align="center" justify="center">图 理想度公式</div>
      </v-row>
      <p>
        要求系统增加
        <triz-validator rules="required" id="提高理想度法则-文本框1">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot01" />
        </triz-validator>
        有用功能； 采用
        <triz-validator rules="required" id="提高理想度法则-文本框2">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot02" />
        </triz-validator>
        方式，降低了系统成本； 采用了
        <triz-validator rules="required" id="提高理想度法则-文本框3">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot03" />
        </triz-validator>
        方法降低了有害功能。 使得系统具备了
        <triz-validator rules="required" id="提高理想度法则-文本框4">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot04" />
        </triz-validator>
        特点。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="提高理想度法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem1" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(2)">
      <p>
        <strong>子系统不均衡进化法则</strong
        >：每个子系统及子系统之间进化都存在这不均衡性，系统中最先达到其极限的子系统将抑制整个系统的进化，系统进化水平取决于该系统。
      </p>
      <p>
        利用子系统不均衡进化法则，我们发现在系统中子系统，属于低理想度的子系统。
        <triz-validator rules="required" id="子系统不均衡进化法则-文本框5">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot05" />
        </triz-validator>
        ； 改进整个不理想的子系统，用
        <triz-validator rules="required" id="子系统不均衡进化法则-文本框6">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot06" />
        </triz-validator>
        系统代替他，则可以最小成本来改进系统的性能。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="子系统不均衡进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem2" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(3)">
      <p><strong>动态性进化法则</strong>：移动性增强、增加自由度、可控性增强。</p>
      <p>
        要求装置缩小自身体积，并针对
        <triz-validator rules="required" id="动态性进化法则-文本框7">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot07" />
        </triz-validator>
        生产环境，增强装置可移动性、提高装置移动自由度， 避免
        <triz-validator rules="required" id="动态性进化法则-文本框8">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot08" />
        </triz-validator>
        后果， 实现
        <triz-validator rules="required" id="动态性进化法则-文本框9">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot09" />
        </triz-validator>
        。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="动态性进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem3" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(4)">
      <p>
        <strong>子系统协调性进化法则</strong
        >：工程系统沿着各子系统之间，以及工程系统和超系统之间更协调的方向进化，逐渐于超系统，与主要功能对象，以及子系统之间相协调，提高性能。
      </p>
      <p>
        要开发的
        <triz-validator rules="required" id="子系统协调性进化法则-文本框10">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot10" />
        </triz-validator>
        系统， 存在着
        <triz-validator rules="required" id="子系统协调性进化法则-文本框11">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot11" />
        </triz-validator>
        （超系统） 或者
        <triz-validator rules="required" id="子系统协调性进化法则-文本框12">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot12" />
        </triz-validator>
        （子系统）相互协调的问题。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="子系统协调性进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem4" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(5)">
      <p>
        <strong>向微观级和场的应用进化法则</strong
        >：在工程系统进化过程中，系统开始转向利用越来越高级别的物质和场，已获得更高的性能或控制性。
      </p>
      <p>
        目前传统
        <triz-validator rules="required" id="向微观级和场的应用进化法则-系统名称">
          {{ triz.system }}
        </triz-validator>
        系统， 受
        <triz-validator rules="required" id="向微观级和场的应用进化法则-文本框13">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot13" />
        </triz-validator>
        限制，存在着
        <triz-validator rules="required" id="向微观级和场的应用进化法则-文本框21">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot21" />
        </triz-validator>
        的问题。而向微观级进化法则，要求装置向小体积、便携方向发展。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="向微观级和场的应用进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem5" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(6)">
      <p>
        <strong>增加集成度再进行简化的进化法则</strong
        >：系统首先向集成度增加的方向进化，紧接着再进行简化。如先增加系统功能的数量和质量，然后再用更简单的系统提供相同或者更好的性能来代替。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="增加集成度再进行简化的进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem6" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(7)">
      <p>
        <strong>能量传递法则</strong
        >：能量传递法则是指能量在从能量源传递到执行装置的时候能量损失最小，途经路径较短，能量转化的形式尽可能少。
      </p>
      <p>
        要求
        <triz-validator rules="required" id="能量传递法则-文本框14">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot14" />
        </triz-validator>
        系统优化结构， 提高流动
        <triz-validator rules="required" id="能量传递法则-文本框15">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot15" />
        </triz-validator>
        动能转化效率，以达到或提高装置运行速度， 在有限的条件下扩大
        <triz-validator rules="required" id="能量传递法则-文本框16">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot16" />
        </triz-validator>
        功能， 在
        <triz-validator rules="required" id="能量传递法则-文本框17">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot17" />
        </triz-validator>
        限制条件下， 缩短
        <triz-validator rules="required" id="能量传递法则-文本框18">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot18" />
        </triz-validator>
        时间， 减少因
        <triz-validator rules="required" id="能量传递法则-文本框19">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot19" />
        </triz-validator>
        所产生的
        <triz-validator rules="required" id="能量传递法则-文本框20">
          <InnerTextField @input="change" v-model="triz.evaluation.slots.slot20" />
        </triz-validator>
        后果。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="能量传递法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem7" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <template v-if="isSelected(8)">
      <p>
        <strong>完备性进化法则</strong
        >：一个系统能够正常工作，必须具备四个功能模块，即执行机构、传动机构、能量源机构和控制机构。有些工程系统在进化的时候，会逐步获得这些机构，成为工程系统的一部分。
      </p>
      <p>通过该法则识别出的问题：</p>
      <triz-validator rules="required" id="完备性进化法则-问题识别">
        <Tinymce v-model="triz.evaluation.problems.problem8" @input="change" placeholder="请输入问题" />
      </triz-validator>
    </template>
    <p>
      根据上面进化法则内容，传统
      <triz-validator rules="required" id="八大进化法则-系统名称">
        {{ triz.system }}
      </triz-validator>
      系统 主要存在{{ triz.evaluation.results.length }}大进化空间：
    </p>
    <template v-for="(evaluation, i) in triz.evaluation.results">
      <div :key="i">
        <v-row no-gutters>
          <p>
            {{ i + 1 }}) 提高
            <triz-validator rules="required" id="八大进化法则-提高目标">
              <InnerTextField @input="change" v-model="evaluation.target" />
            </triz-validator>
            -
          </p>
          <div class="Triz_Select">
            <triz-validator rules="required" id="八大进化法则-功能类型">
              <triz-select
                :table="false"
                solo
                v-model="evaluation.type"
                :items="functionTypes"
                label="选择功能"
                @change="change"
              >
              </triz-select>
            </triz-validator>
          </div>
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="red" @click="removeEvaluation(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <triz-validator rules="required" id="八大进化法则-功能详细">
          <Tinymce v-model="evaluation.detail" @input="change" placeholder="请输入功能详细" />
        </triz-validator>
      </div>
    </template>
    <p>
      <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addEvaluation">
        <v-icon left>mdi-plus</v-icon> 添加进化内容
      </v-btn>
    </p>
    <p>
      增强装置适用性，针对这{{
        triz.evaluation.results.length
      }}个问题，后文将使用TRIZ理论方法中的技术矛盾分析、物理矛盾分析、40个发明原理等方法进行解决。
    </p>
  </div>
</template>

<script>
import InnerTextField from '@/components/InnerTextField'
import Tinymce from '@/components/Tinymce'
import { evaluationDetail, functionTypes, evaluations } from '../../../store/default'

export default {
  components: {
    InnerTextField,
    Tinymce,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      functionTypes,
      evaluations,
      // // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    addEvaluation() {
      this.triz.evaluation.results.push({
        target: '',
        type: 1,
        detail: evaluationDetail,
      })
      this.change()
    },
    removeEvaluation(i) {
      this.triz.evaluation.results.splice(i, 1)
      this.change()
    },
    isSelected(number) {
      console.log(number)
      if (!this.triz.evaluation.selectedEvaluations) return false
      return this.triz.evaluation.selectedEvaluations.includes(number)
    },
  },
}
</script>
<style lang="scss">
.jhfz_padding {
  .v-select__selections {
    text-indent: 0 !important;
  }
}
</style>
