<template>
  <v-container style="font-size: 24px">
    <v-row align="center" justify="center">
      <IdealTable v-model="triz.idealResolveAnalyze" @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 图 {{ triz.system }}的最终理想解分析 </v-row>
    <v-row>
      <p>
        根据最终理想解可以分析，若想达到最终理想解，则需要
        <triz-validator rules="required" id="最终理想解-文本框1">
          <InnerTextField @input="change" v-model="triz.idealResolveSlots.slot01" />
        </triz-validator>
        等资源，即要达到最终理想解，需改变
        <triz-validator rules="required" id="最终理想解-文本框2">
          <InnerTextField @input="change" v-model="triz.idealResolveSlots.slot02" />
        </triz-validator>
        的功能和方式。
      </p>
    </v-row>
    <template v-for="(resolve, i) in triz.idealResolves">
      <div :key="`resolve-${i}`">
        <v-row no-gutters>
          理想解决方案{{ i + 1 }}：
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="red" @click="removeIdeal(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <triz-validator rules="required" id="最终理想解-解决方案">
          <Tinymce v-model="resolve.solutionDetail" @input="change" placeholder="请输入解决方案" />
        </triz-validator>
      </div>
    </template>
    <p>
      <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addIdeal">
        <v-icon left>mdi-plus</v-icon> 添加解决方案
      </v-btn>
    </p>
  </v-container>
</template>

<script>
import IdealTable from '@/components/IdealTable'
import InnerTextField from '@/components/InnerTextField'
import Tinymce from '@/components/Tinymce'

export default {
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  components: {
    IdealTable,
    InnerTextField,
    Tinymce,
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    addIdeal() {
      const id = `physical-${new Date().getTime()}`
      this.triz.idealResolves.push({
        id,
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeIdeal(i) {
      const [{ id }] = this.triz.idealResolves.splice(i, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
  },
}
</script>
