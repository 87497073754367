<template>
  <v-container ref="content" style="width: 1100px; padding: 0" @mouseover="onMouseOver" @click="onMouseClick">
    <v-card color="white" elevation="1" style="">
      <v-form v-if="triz" :readonly="locked">
        <v-row no-gutters>
          <v-col no-gutters cols="12">
            <template v-for="main in steps">
              <v-row
                style="font-size: 30px; margin-left: 30px; margin-right: 30px; margin-top: 30px"
                :key="main.id"
                :id="`step-${main.id}`"
              >
                <v-col>
                  <SigmentLink :label="`第${main.id}部分：${main.title}`" :goto="`#step-${main.id}`" />
                </v-col>
              </v-row>
              <template
                v-for="(sub, subId) in main.children.filter(
                  (ele) =>
                    ![2, 4].includes(main.id) ||
                    (main.id === 2 && triz.selectedRecognizeSteps.find((step) => step.id === ele.id)) ||
                    (main.id === 4 && triz.selectedResolveSteps.find((step) => step.id === ele.id))
                )"
              >
                <v-row
                  style="font-size: 26px; margin-left: 32px; margin-right: 32px"
                  :key="`step-${main.id}-${subId + 1}`"
                  :id="`step-${main.id}-${sub.id}`"
                  v-intersect="{
                    handler: onIntersect,
                    options: {
                      threshold: [0.5],
                    },
                  }"
                >
                  <v-col cols="5">
                    <SigmentLink
                      :label="`${main.id}.${subId + 1} - ${sub.title}`"
                      :goto="`#step-${main.id}-${subId + 1}`"
                    />
                  </v-col>
                  <v-col cols="7" class="col_analyzing_tool_btn">
                    <v-btn
                      v-show="!locked && main.id === 2"
                      tile
                      outlined
                      color="red"
                      @click="removeRecognizeStep(sub)"
                    >
                      <v-icon left>mdi-minus</v-icon>删除此工具
                    </v-btn>
                    <v-btn v-show="!locked && main.id === 4" tile outlined color="red" @click="removeResolveStep(sub)">
                      <v-icon left>mdi-minus</v-icon>删除此工具
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  :key="`component-${main.id}-${sub.id}`"
                  style="margin-left: 32px; margin-right: 32px"
                  :id="`component-${main.id}-${sub.id}`"
                  v-intersect="{
                    handler: onIntersect,
                    options: {
                      threshold: [0.5],
                    },
                  }"
                >
                  <v-col>
                    <keep-alive>
                      <component class="mr-8" :is="sub.component" :triz="triz" :parentid="`${main.id}.${sub.id}`" @change="change" />
                    </keep-alive>
                  </v-col>
                </v-row>
                <!-- </v-lazy> -->
                <template v-for="(tail, tailId) in sub.children">
                  <v-row
                    :id="`step-${main.id}-${sub.id}-${tail.id}`"
                    :key="`step-${main.id}-${subId + 1}-${tailId + 1}`"
                    style="font-size: 26px; margin-left: 36px; margin-right: 36px"
                    v-intersect="{
                      handler: onIntersect,
                      options: {
                        threshold: [0.5],
                      },
                    }"
                  >
                    <v-col cols="5">
                      <SigmentLink
                        :label="`${main.id}.${subId + 1}.${tailId + 1} - ${tail.title}`"
                        :goto="`#step-${main.id}-${subId + 1}-${tailId + 1}`"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    :id="`component-${main.id}-${sub.id}-${tail.id}`"
                    :key="`component-${main.id}-${subId + 1}-${tailId + 1}`"
                    style="margin-left: 32px; margin-right: 32px"
                    v-intersect="{
                      handler: onIntersect,
                      options: {
                        threshold: [0.5],
                      },
                    }"
                  >
                    <v-col>
                      <keep-alive>
                        <component class="mr-8" :is="tail.component" :parentid="`${main.id}.${sub.id}.${tail.id}`" :triz="triz" @change="change" />
                      </keep-alive>
                    </v-col>
                  </v-row>
                </template>
              </template>

              <template v-if="main.id == 2 && !locked">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="analyzing_tool_btn">
                      <v-btn
                        v-if="triz.selectedRecognizeSteps.length < 7"
                        dense
                        class="ma-2"
                        tile
                        outlined
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>mdi-plus</v-icon> 添加分析工具
                      </v-btn>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      color="primary"
                      v-for="(method, index) in main.children.filter(
                        (ele) => !triz.selectedRecognizeSteps.some((step) => step.id === ele.id)
                      )"
                      :key="index"
                    >
                      <v-btn dense class="ma-2" tile outlined color="success" @click="addRecognizeStep(method)">
                        <v-icon left>mdi-plus</v-icon> {{ method.title }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-if="main.id == 4 && !locked">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="analyzing_tool_btn">
                      <v-btn
                        v-if="triz.selectedResolveSteps.length < 3"
                        dense
                        class="ma-2"
                        tile
                        outlined
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>mdi-plus</v-icon> 添加解决工具
                      </v-btn>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      color="primary"
                      v-for="(method, index) in main.children.filter(
                        (ele) => !triz.selectedResolveSteps.some((step) => step.id === ele.id)
                      )"
                      :key="index"
                    >
                      <v-btn dense class="ma-2" tile outlined color="success" @click="addResolveStep(method)">
                        <v-icon left>mdi-plus</v-icon> {{ method.title }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-snackbar bottom v-model="showMenu"> <strong>可添加批注 | </strong> {{ remarkTarget }} </v-snackbar>
    <v-bottom-sheet :value="showRemark" inset :hide-overlay="false" :persistent="showRemark" :retain-focus="false">
      <v-card>
        <v-text-field v-model="remark" outlined dense :label="`${remarkTarget}的批注`"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showRemark = false">关闭</v-btn>
          <v-btn color="primary" text @click="addRemark">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import Clip from './component/Clip'
import Description from './component/Description.vue'
import TechRoad from './component/TechRoad.vue'
import Evaluation from './component/Evaluation.vue'
import FinalResolve from './component/FinalResolve.vue'
import Functional from './component/Functional.vue'
import FunctionalComponent from './component/FunctionalComponent.vue'
import FunctionalEffect from './component/FunctionalEffect.vue'
import FunctionalModel from './component/FunctionalModel.vue'
import ProblemAnalysis from './component/ProblemAnalysis.vue'
import Judgement from './component/Judgement.vue'
import Nine from './component/Nine.vue'
import Physical from './component/Physical.vue'
import Reason from './component/Reason.vue'
import Resource from './component/Resource.vue'
import Result from './component/Result.vue'
import SCurve from './component/SCurve.vue'
import Situation from './component/Situation.vue'
import Solution from './component/Solution.vue'
import Experiment from './component/Experiment.vue'
import KeyProblem from './component/KeyProblem.vue'
import GuideSearch from './component/GuideSearch.vue'
import StandardResovle from './component/StandardResovle.vue'

import BackgroudInit from './component/1-2-1-Init.vue'
import BackgroudCurrent from './component/1-2-2-Current.vue'
import BackgroudTarget from './component/1-2-3-Target.vue'
import BackgroudBusiness from './component/1-2-4-Business.vue'
import BackgroudCalculate from './component/1-2-5-Calculate.vue'
import BackgroudLimit from './component/1-2-6-Limit.vue'
import BackgroudAllow from './component/1-2-7-Allow.vue'

import SigmentLink from '@/components/SigmentLink'

import { TRIZ_STEPS } from './const'

export default {
  name: 'App',
  components: {
    Clip,
    Description,
    Evaluation,
    FinalResolve,
    Functional,
    FunctionalComponent,
    FunctionalEffect,
    FunctionalModel,
    ProblemAnalysis,
    Judgement,
    Nine,
    Physical,
    Reason,
    Resource,
    Result,
    SCurve,
    Situation,
    Solution,
    Experiment,
    KeyProblem,
    SigmentLink,
    GuideSearch,
    BackgroudInit,
    BackgroudCurrent,
    BackgroudTarget,
    BackgroudBusiness,
    BackgroudCalculate,
    BackgroudLimit,
    BackgroudAllow,
    TechRoad,
    StandardResovle,
  },
  props: {
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // triz: null,
      steps: [...TRIZ_STEPS],
      tab: null,
      showMenu: false,
      showRemark: false,
      remark: '',
      remarkTarget: '',
    }
  },
  // watch: {
  //   triz: {
  //     handler(triz) {
  //       this.change(triz)
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    // this.triz = this.$store.state.triz
  },
  computed: {
    triz() {
      return this.$store.state.triz
    },
    locked() {
      return this.$store.state.locked
    },
    remarks() {
      return this.$store.state.remarks
    },
  },
  methods: {
    onIntersect(entries) {
      // if (entries[0].intersectionRatio >= 0.5) {
      //   this.$store.commit('intersect', entries[0].target.id)
      // }
    },
    change(data) {
      this.$store.commit('updateTriz', data)
      this.$store.commit('do', data)
    },
    async save(projectId, version) {
      await this.$store.dispatch('saveTriz', { projectId, version })
      await this.$store.commit('dirty', false)
    },
    saveReview(projectId, reviewId) {
      this.$store.dispatch('insertReview', {
        projectId,
        reviewId,
        reviewStatus: '1',
        annotationContent: { remarks: this.remarks },
      })
    },
    finishReview(projectId, reviewId) {
      this.$store.dispatch('insertReview', {
        projectId,
        reviewId,
        reviewStatus: '2',
        annotationContent: { remarks: this.remarks },
      })
    },
    onMouseOver(event) {
      if (!this.isReview || !this.locked || this.showRemark) return
      const ele = (event.path || event.composedPath()).find((ele) => ele.nodeName === 'SPAN' && ele.id)
      if (ele) {
        this.remarkTarget = ele.id
        const curr = this.remarks.find((rem) => rem.target === this.remarkTarget)
        if (curr) {
          this.remark = curr.comment
        } else {
          this.remark = ''
        }

        this.showMenu = true
      } else {
        this.showMenu = false
      }
    },
    onMouseClick() {
      if (!this.isReview || !this.locked) return
      const ele = (event.path || event.composedPath()).find((ele) => ele.nodeName === 'SPAN' && ele.id)
      if (ele) {
        this.showMenu = false
        this.showRemark = true
      }
    },
    addRemark() {
      this.showRemark = false
      const curr = this.remarks.find((rem) => rem.target === this.remarkTarget)
      if (curr) {
        curr.comment = this.remark
      } else {
        this.remarks.push({
          target: this.remarkTarget,
          comment: this.remark,
        })
      }
    },
    removeRemark(i) {
      this.remarks.splice(i, 1)
    },
    addRecognizeStep(sub) {
      this.triz.selectedRecognizeSteps.push(sub)
      this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
      this.$store.commit('updateTriz', this.triz)
      this.$store.commit('do', this.triz)
    },
    removeRecognizeStep(sub) {
      const step = this.triz.selectedRecognizeSteps.find((ele) => ele.id === sub.id)
      if (step) {
        const index = this.triz.selectedRecognizeSteps.findIndex((ele) => ele.id === sub.id)
        this.triz.selectedRecognizeSteps.splice(index, 1)
        this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
        this.$store.commit('updateTriz', this.triz)
        this.$store.commit('do', this.triz)
      }
    },
    addResolveStep(sub) {
      this.triz.selectedResolveSteps.push(sub)
      this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
      this.$store.commit('updateTriz', this.triz)
      this.$store.commit('do', this.triz)
    },
    removeResolveStep(sub) {
      const step = this.triz.selectedResolveSteps.find((ele) => ele.id === sub.id)
      if (step) {
        const index = this.triz.selectedResolveSteps.findIndex((ele) => ele.id === sub.id)
        this.triz.selectedResolveSteps.splice(index, 1)
        this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
        this.$store.commit('updateTriz', this.triz)
        this.$store.commit('do', this.triz)
      }
    },
  },
}
</script>
<style lang="scss">
.col_analyzing_tool_btn {
  position: relative;
  .v-btn {
    position: absolute;
    right: 30px;
  }
}
.analyzing_tool_btn {
  margin: 0 50px;
  display: flex;
  position: relative;
  .v-btn {
    position: absolute;
    right: 0;
  }
}
</style>
