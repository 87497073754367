<template>
  <vv-observer slim v-slot="{ validateWithInfo, dirty }">
    <div id="app" style="background-color: #e0e0e0">
      <v-navigation-drawer
        width="350"
        v-model="drawerLeft"
        :clipped="$vuetify.breakpoint.lgAndUp"
        fixed
        app
        color="#90CAF9"
        class="bj_left_app"
        floating
      >
        <v-stepper
          v-if="triz"
          color="#90CAF9"
          :value="scrollIndex.main"
          class="my-auto bj_left_min_height"
          flat
          outlined
          non-linear
          vertical
        >
          <template v-for="main in steps">
            <v-stepper-step :key="main.id" editable :step="main.id">{{ main.title }}</v-stepper-step>
            <v-stepper-content :key="`sub-${main.id}`" v-if="main.children" :step="main.id">
              <v-stepper :value="scrollIndex.sub" class="transparent" flat outlined non-linear width="100%" vertical>
                <template
                  v-for="(sub, subIndex) in locked
                    ? main.children.filter(
                        (ele) =>
                          ![2, 4].includes(main.id) ||
                          (main.id === 2 && triz.selectedRecognizeSteps.find((step) => step.id === ele.id)) ||
                          (main.id === 4 && triz.selectedResolveSteps.find((step) => step.id === ele.id))
                      )
                    : main.children"
                >
                  <v-stepper-step
                    :key="`${main.id}-${sub.id}`"
                    editable
                    :step="computeStepNumber(main, sub, subIndex)"
                    class="left_triz_display"
                    ><div
                      style="width: 150px"
                      @click="
                        $vuetify.goTo(`#step-${main.id}-${sub.id}`, {
                          duration: 1000,
                          offset: 100,
                          easing: 'easeInOutCubic',
                        })
                      "
                    >
                      {{ sub.title }}
                    </div>
                    <v-checkbox
                      v-show="!locked && main.id === 2"
                      :input-value="isRecognizeStepSelected(sub)"
                      dense
                      tile
                      outlined
                      x-small
                      color="red"
                      class="delete_tool"
                      @change="changeRecognizeStep($event, sub)"
                    >
                      <v-icon left size="18">mdi-close-circle</v-icon>
                    </v-checkbox>
                    <v-checkbox
                      v-show="!locked && main.id === 4"
                      :input-value="isResolveStepSelected(sub)"
                      dense
                      tile
                      outlined
                      x-small
                      color="red"
                      class="delete_tool"
                      @change="changeResolveStep($event, sub)"
                    >
                    </v-checkbox>
                  </v-stepper-step>
                  <v-stepper-content
                    :key="`tail-${sub.id}`"
                    v-if="sub.children"
                    :step="computeStepNumber(main, sub, subIndex)"
                    class="children_Content"
                  >
                    <v-stepper :value="scrollIndex.tail" flat outlined non-linear width="100%" vertical>
                      <v-stepper-step
                        v-for="(tail, tailIndex) in sub.children"
                        :key="`tail-${main.id}-${sub.id}-${tail.id}-${tailIndex}`"
                        :editable="false"
                        :step="computeTailNumber(main, sub, tail, tailIndex)"
                        class="left_triz_displayChildren"
                      >
                        <span
                          @click="
                            $vuetify.goTo(`#step-${main.id}-${sub.id}-${tail.id}`, {
                              duration: 1000,
                              offset: 100,
                              easing: 'easeInOutCubic',
                            })
                          "
                          >{{ tail.title }}</span
                        >
                      </v-stepper-step>
                    </v-stepper>
                  </v-stepper-content>
                </template>
              </v-stepper>
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-navigation-drawer>

      <v-navigation-drawer
        style="border-left: 1px solid #ddd"
        width="470"
        class="bj_right_app"
        :value="drawerRight"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        fixed
        right
        floating
      >
        <v-tabs v-model="tab" outlined grow class="tab_topStop">
          <v-tab v-if="!review" href="#tab-1"
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon>mdi-help</v-icon>
                </span>
              </template>
              <span>帮助</span>
            </v-tooltip></v-tab
          >

          <v-tab v-if="!review" href="#tab-2"
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </span>
              </template>
              <span>问题</span>
            </v-tooltip></v-tab
          >

          <v-tab v-if="reviewId" href="#tab-3"
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon>mdi-human-male-board</v-icon>
                </span>
              </template>
              <span>批注</span>
            </v-tooltip></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-if="!review" value="tab-1">
            <component class="mr-8" :is="help" />
          </v-tab-item>
          <v-tab-item v-if="!review" value="tab-2" class="overflow-y-auto">
            <template v-for="(field, key) in fields">
              <v-alert
                v-for="(rule, i) in field.failedRules"
                :key="`error-${key}-${i}`"
                class="mx-2 my-4"
                dismissible
                color="orange"
                border="left"
                elevation="2"
                colored-border
                dense
                outlined
                v-ripple
                @click="jumpToField(key)"
              >
                <strong>{{ `${key} | ` }}</strong
                >{{ `${rule}` }}
              </v-alert>
            </template>
          </v-tab-item>
          <v-tab-item v-if="reviewId" value="tab-3">
            <v-alert
              v-for="(remark, i) in remarks"
              :key="`remark-${i}`"
              class="mx-2 my-4"
              color="blue"
              border="left"
              elevation="2"
              colored-border
              dense
              outlined
              v-ripple
              @click="jumpToField(remark)"
            >
              <strong>{{ `${remark.target} | ` }}</strong
              >{{ `${remark.comment}` }}
              <v-btn fab x-small @click="removeRemark(i)"><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-alert>
          </v-tab-item>
        </v-tabs-items>
      </v-navigation-drawer>

      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        :clipped-right="$vuetify.breakpoint.lgAndUp"
        app
        dark
        color="#3f81c1"
        height="55px"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon @click.stop="drawerLeft = !drawerLeft">
                <v-icon :style="`${drawerLeft ? 'transform: rotateY(0deg)' : 'transform: rotateY(180deg)'}`"
                  >mdi-backburger</v-icon
                >
              </v-app-bar-nav-icon>
            </span>
          </template>
          <span>左侧边栏</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                :disabled="loadingExport || loadingSave"
                class="ml-2"
                bottom
                color="green"
                dark
                fab
                left
                small
                @click="onExit"
                ><v-icon dark>mdi-exit-run</v-icon></v-btn
              >
            </span>
          </template>
          <span>离开</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-if="!review"
                class="ml-2"
                bottom
                color="purple lighten-2"
                dark
                fab
                left
                small
                @click="onSaveClick"
                :disabled="loadingSave || loadingExport"
                :loading="loadingSave"
                ><v-icon dark>mdi-content-save</v-icon></v-btn
              >
            </span>
          </template>
          <span>保存</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-if="review"
                class="ml-2"
                bottom
                color="purple lighten-2"
                dark
                fab
                left
                small
                @click="onReviewSave"
                ><v-icon dark>mdi-content-save</v-icon></v-btn
              >
            </span>
          </template>
          <span>保存审阅</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <span class="hidden-sm-and-down ml-2" style="margin: 0 auto; font-size: 22px"
          ><strong>{{ title }}</strong></span
        >
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span v-show="loadingExport">{{ exportLabel }}</span>
              <v-btn
                v-show="locked && !review"
                class="mr-2"
                bottom
                color="blue"
                dark
                fab
                right
                small
                :loading="loadingExport"
                :disabled="loadingExport"
                @click="onExportClick"
                ><v-icon dark>mdi-file-export</v-icon></v-btn
              >
            </span>
          </template>
          <span>导出文档</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-show="!locked"
                class="mr-2"
                bottom
                color="cyan"
                dark
                fab
                right
                small
                @click="onUndoClick"
                :disabled="loadingSave || !trizMutations.length || (trizMutations.length && mutationIndex == 0)"
                ><v-icon dark>mdi-arrow-left</v-icon></v-btn
              >
            </span>
          </template>
          <span>撤销编辑</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-show="!locked"
                class="mr-2"
                bottom
                color="cyan"
                dark
                fab
                right
                small
                @click="onRedoClick"
                :disabled="
                  loadingSave ||
                  !trizMutations.length ||
                  (trizMutations.length && mutationIndex >= trizMutations.length - 1)
                "
                ><v-icon dark>mdi-arrow-right</v-icon></v-btn
              >
            </span>
          </template>
          <span>恢复编辑</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-show="!locked"
                class="mr-2"
                bottom
                color="red"
                dark
                fab
                right
                small
                @click="onCheckClick(validateWithInfo)"
                ><v-icon dark>mdi-check</v-icon></v-btn
              >
            </span>
          </template>
          <span>输入验证</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-show="locked && review"
                class="mr-2"
                bottom
                color="red"
                dark
                fab
                right
                small
                @click="onReviewFinish(validateWithInfo)"
                ><v-icon dark>mdi-check</v-icon></v-btn
              >
            </span>
          </template>
          <span>完成审阅</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                :disabled="loadingExport || loadingSave"
                v-if="!review"
                class="mr-2"
                bottom
                color="orange"
                dark
                fab
                right
                small
                @click="onLockClick"
                ><v-icon dark>{{ locked ? 'mdi-lock' : 'mdi-lock-open-variant' }}</v-icon></v-btn
              >
            </span>
          </template>
          <span>编辑锁</span>
        </v-tooltip>

        <!-- <v-btn class="mr-2" bottom color="brown lighten-1" dark fab right small @click="onLockClick"
          ><v-icon dark>{{ locked ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></v-btn
        > -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon
                @click.stop="
                  () => {
                    $store.commit('drawerRight', !drawerRight)
                  }
                "
              >
                <v-icon :style="`${drawerRight ? 'transform: rotateY(180deg)' : 'transform: rotateY(0deg)'}`"
                  >mdi-backburger</v-icon
                >
              </v-app-bar-nav-icon>
            </span>
          </template>
          <span>右侧边栏</span>
        </v-tooltip>
      </v-app-bar>
      <Content ref="content" :key="contentKey" :dirty="dirty" :isReview="review" />
    </div>
    <v-dialog v-model="cacheDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 发现本地缓存 </v-card-title>
        <v-card-text>有最新缓存内容，是否要读取？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="loadDb()"> 不用了 </v-btn>
          <v-btn color="green darken-1" text @click="loadCache()"> 好的 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </vv-observer>
</template>

<script>
import Content from './content.vue'
import { TRIZ_STEPS } from './const'
import FileSaver from 'file-saver'
import Handlebars from 'handlebars'
import HTMLtoDOCX from '../../docx'
import localforage from 'localforage'
import { defaultTriz, keyWords } from '../../store/default'
import html2canvas from 'html2canvas'
import { parameters, principles } from '../../store/altshuller'
import { lineShapes, lineShapesText } from '../../store/default'
import {
  obj_field_level_1,
  obj_field_level_2,
  obj_field_level_3,
  obj_field_level_4,
  obj_field_level_5,
} from '../../store/objfield.js'

import clipRule from './component/help/clip-rule'
import techRoad from './component/help/tech-road'

export default {
  components: {
    Content: Content,
    clipRule,
    techRoad,
    // Mark: Mark,
  },
  data: () => ({
    projectId: '',
    dialog: false,
    drawerLeft: true,
    items: [],
    steps: [...TRIZ_STEPS],
    tab: null,
    fields: {},
    title: '',
    cacheDialog: false,
    foundedData: null,
    reviewId: '',
    resume: '',
    review: false,
    loadingExport: false,
    loadingSave: false,
    exportLabel: '正在导出，请耐心等待...',
  }),
  computed: {
    triz() {
      return this.$store.state.triz
    },
    scrollIndex() {
      const index = this.$store.state.scrollIndex.split('-')
      return {
        main: index[1],
        sub: `${index[1]}-${index[2]}`,
        tail: `${index[1]}-${index[2]}-${index[3]}`,
      }
    },
    locked() {
      return this.$store.state.locked
    },
    contentKey() {
      return this.$store.state.contentKey
    },
    mutationIndex() {
      return this.$store.state.mutationIndex
    },
    trizMutations() {
      return this.$store.state.trizMutations
    },
    remarks() {
      return this.$store.state.remarks
    },
    dirty() {
      return this.$store.state.dirty
    },
    help() {
      return this.$store.state.help
    },
    drawerRight() {
      return this.$store.state.drawerRight
    },
  },
  destroy() {
    window.removeEventListener('beforeunload', this.beforeunloadHandler, false)
  },
  //监听当前页面返回事件
  async beforeRouteLeave(to, from, next) {
    const ok = await this.$dialog.confirm({
        text: '您确定要退出吗？',
        title: '请确认',
        actions: {
          false: '不',
          true: '是的',
        },
      })
      if (!ok) {
        next(false)
        return
      }
      next()
  },
  async mounted() {
    window.addEventListener('beforeunload', this.beforeunloadHandler, false)
    this.$dialog.withLoading({}, async () => {
      this.projectId = this.$route.params.projectId
      this.reviewId = this.$route.query.reviewId
      this.resume = this.$route.query.resume

      // 获取当前批注
      if (this.reviewId) {
        const reviewRes = await this.$store.dispatch('findReview', this.reviewId)
        if (
          reviewRes.data.annotationContent &&
          reviewRes.data.annotationContent.remarks &&
          reviewRes.data.annotationContent.remarks.length
        ) {
          this.$store.commit('setRemarks', reviewRes.data.annotationContent.remarks)
        }
      }
      this.review = this.$route.query.review === 'true'
      // const localVersion = await localforage.getItem(`key-${this.projectId}`)
      this.$store.commit('switchProject', this.projectId)

      // 获取当前项目内容
      // 审阅模式下获取对应版本
      if (this.resume) {
        const res = await this.$store.dispatch('findRevision', {
          projectId: this.projectId,
          revisionNumber: this.resume,
        })
        if (!res.data || !res.data.jsonContent) return
        this.foundedData = res.data.jsonContent
        this.initTrizState(this.foundedData)
        return
      }

      // 编辑模式下获取最新版本
      const res = await this.$store.dispatch('getTriz', this.projectId)
      if (!res.data) return
      const data = res.data
      this.$store.commit('updateVersion', data.version)
      // 项目名
      this.title = data.projectName
      // 数据库没有内容
      if (!data.jsonContent) {
        // // 有缓存
        // if (localVersion) {
        //   this.foundedData = defaultTriz
        //   this.cacheDialog = true
        //   return
        // }
        // 无缓存
        this.initTrizState(defaultTriz)
        return
      }
      // 数据库有内容
      this.foundedData = data.jsonContent
      // // 无缓存
      // if (!localVersion) {
      this.initTrizState(this.foundedData)
      return
      // }
      // // 有缓存，版本低
      // if (localVersion <= data.version) {
      //   this.initTrizState(this.foundedData)
      //   return
      // }
      // // 有缓存，版本高
      // this.cacheDialog = true
    })
  },
  methods: {
    beforeunloadHandler(e) {
      // e.preventDefault()
      // e.returnValue = ''
      e.returnValue = '确'
    },
    loadDb() {
      this.initTrizState(this.foundedData)
      this.cacheDialog = false
      localforage.removeItem(`key-${this.projectId}`)
      localforage.removeItem(`content-${this.projectId}`)
    },
    async loadCache() {
      const cachedData = await localforage.getItem(`content-${this.projectId}`)
      this.initTrizState(cachedData)
      this.cacheDialog = false
    },
    initTrizState(data) {
      this.$store.commit('updateTriz', JSON.parse(JSON.stringify(data)))
      this.$store.commit('setMutations', JSON.parse(JSON.stringify(data)))
    },
    onLockClick() {
      this.$store.commit('lockTriz')
    },
    async onSaveClick() {
      try {
        this.loadingSave = true
        await this.$refs.content.save(this.$route.params.projectId, await localforage.getItem(`key-${this.projectId}`))
        this.$dialog.message.success('保存成功', {
          position: 'top-center',
          timeout: 2000,
        })
      } catch (e) {
        this.$dialog.message.error('保存失败', {
          position: 'top-center',
          timeout: 2000,
        })
      } finally {
        this.loadingSave = false
      }
    },
    async onReviewSave() {
      this.$refs.content.saveReview(this.$route.params.projectId, this.reviewId)
    },
    async onReviewFinish() {
      this.foundedData = null
      const ok = await this.$dialog.confirm({
        text: '您确定已经完成审阅了吗？',
        title: '请确认',
        actions: {
          false: '不',
          true: '是的',
        },
      })
      if (!ok) return
      this.$refs.content.finishReview(this.$route.params.projectId, this.reviewId)
      this.$router.push('/my/review')
    },
    removeRemark(i) {
      this.$refs.content.removeRemark(i)
    },
    async onExportClick() {
      const ok = await this.$dialog.confirm({
        text: '根据创新项目具体要求，请在导出后自行更改相关内容。\n<string>部分手机浏览存在问题，如需手机端传输建议转为pdf文件。</string>',
        title: '请确认',
        actions: {
          false: '取消',
          true: '知道了',
        },
      })
      if (!ok) return

      const triz = JSON.parse(JSON.stringify(this.triz))
      console.log('export', triz)
      Handlebars.registerHelper('list', function (context, options) {
        let out = ''
        let data = null
        if (options.data) {
          data = Handlebars.createFrame(options.data)
        }
        if (context && context.length) {
          for (var i = 0; i < context.length; i++) {
            if (data) {
              data.index = i + 1
            }
            out += options.fn(context[i], { data: data })
          }
        }
        return out
      })

      Handlebars.registerHelper('isequal', function (sour, targ) {
        return sour === targ
      })

      //注册索引+1的helper
      Handlebars.registerHelper('rememberIndex', function (index) {
        //利用+1的时机，在父级循环对象中添加一个_index属性，用来保存父级每次循环的索引
        this._index = index
        //返回+1之后的结果
        return this._index
      })

      Handlebars.registerHelper('rowspan', function(rowspan) {
          return new Handlebars.SafeString(`rowspan="${rowspan}"`)
      })

      try {
        this.loadingExport = true
        this.exportLabel = '正在全力导出，请耐心等待...'
        const imageMap = await this.prepareUserImages()
        const exportData = await this.prepareExportData(triz)
        console.log(exportData)
        let templatePath = process.env.VUE_APP_PUBLIC_PATH + '/template.html'
        const response = await fetch(templatePath)
        const template = await response.text()
        const compiled = Handlebars.compile(template)

        let html = compiled(exportData)
        // console.log('html', html)

        for (const [img, base64] of imageMap.entries()) {
          html = html.replace(
            `<p><img src="${img.src}"`,
            `<p><img src="${base64}" width="${800}" height="${(img.height / img.width) * 800}" `
          )
        }

        html = html.replace(/<width=" /, '<img width="900" ')
        const iteratorWidth = html.matchAll(/width="(.*?)"/g)
        for (const values of iteratorWidth) {
          html = html.replace(values[0], `width="${Number(values[1]) * 0.7}"`)
        }
        const iteratorHeight = html.matchAll(/height="(.*?)"/g)
        for (const values of iteratorHeight) {
          html = html.replace(values[0], `height="${Number(values[1]) * 0.7}"`)
        }

        // const converted = htmlDocx.asBlob(html)
        const converted = await await HTMLtoDOCX(
          html,
          'Triz创新方法论',
          {
            font: '宋体',
            // header: true,
            // footer: true,
            fontSize: '12pt',
            pageNumber: true,
          },
          this.title
        )
        FileSaver.saveAs(converted, `${this.title}.docx`)

        let blob1 = new Blob([html], { type: 'html;charset=utf-8' })
        FileSaver.saveAs(blob1, `${this.title}.html`)
      } finally {
        this.loadingExport = false
      }
    },
    average(solution) {
      const result = (solution.priceScore + solution.complexScore + solution.implementScore + solution.effectScore) / 4
      return isNaN(result) ? '' : result
    },
    async prepareUserImages() {
      this.exportLabel = '正在整理富文本图片...'
      const imageMap = new Map()
      const images = document.querySelectorAll('img')
      for (const img of images.values()) {
        if (!img.src.startsWith(process.env.VUE_APP_OSS_BASE_URL)) {
          continue
        }

        const res = await fetch(img.src, {
          method: 'get',
          responseType: 'blob',
        })
        const blob = await res.blob()
        let oFileReader = new FileReader()
        oFileReader.onloadend = function (e) {
          // 此处拿到的已经是 base64的图片了
          let base64 = e.target.result
          imageMap.set(img, base64)
        }
        oFileReader.readAsDataURL(blob)
      }
      return imageMap
    },
    async prepareExportData(triz) {
      // 整理导出用base64图片

      // 技术路线图
      this.exportLabel = '正在生成技术路线图...'
      let base64ImgRoad
      if (document.querySelector('#road-map')) {
        let canvas = await html2canvas(document.querySelector('#road-map') /*, {scale: 0.5}*/)
        base64ImgRoad = `<img width="900" height="${(canvas.height / canvas.width) * 900}" src="${canvas.toDataURL(
          'image/png'
        )}" />`
      }

      // 九屏分析图
      // let base64ImgNine
      // if (document.querySelector('#nine-graph')) {
      //   let canvas = await html2canvas(document.querySelector('#nine-graph') /*, {scale: 0.5}*/)
      //   base64ImgNine = `<img width="900" height="${(canvas.height / canvas.width) * 900}" src="${canvas.toDataURL(
      //     'image/png'
      //   )}" />`
      // }

      // 因果树图
      this.exportLabel = '正在生成因果树图...'
      let base64ImgTree
      if (document.querySelector('#reasion-tree')) {
        let canvas = await html2canvas(document.querySelector('#reasion-tree'))
        base64ImgTree = `<img width="900" height="${(canvas.height / canvas.width) * 900}" src="${canvas.toDataURL(
          'image/png'
        )}" />`
      }

      // 功能组件图
      this.exportLabel = '正在生成组件图...'
      let base64ImgSystemComponents
      if (document.querySelector('#system-components')) {
        let canvas = await html2canvas(document.querySelector('#system-components'))
        base64ImgSystemComponents = `<img width="900" height="${
          (canvas.height / canvas.width) * 900
        }" src="${canvas.toDataURL('image/png')}" />`
      }

      // 功能关系表
      this.exportLabel = '正在生成相互作用关系图...'
      let base64ImgRelationTable
      if (document.querySelector('#relation-table')) {
        let canvas = await html2canvas(document.querySelector('#relation-table'))
        base64ImgRelationTable = `<img width="900" height="${
          (canvas.height / canvas.width) * 900
        }" src="${canvas.toDataURL('image/png')}" />`
      }

      // 功能关系图
      this.exportLabel = '正在生成功能关系图...'
      let base64ImgGraphModel
      if (document.querySelector('#relation-graph-model')) {
        let canvas = await html2canvas(document.querySelector('#relation-graph-model'))
        base64ImgGraphModel = `<img width="900" height="${
          (canvas.height / canvas.width) * 900
        }" src="${canvas.toDataURL('image/png')}" />`
      }

      // 功能裁剪图
      this.exportLabel = '正在生成功能剪裁图...'
      for (let i = 0; i < triz.clips.length; i++) {
        const clip = triz.clips[i]
        let base64ImgTreeGraphClip
        let base64ImgTreeGraphClipAfter
        if (document.querySelector(`#relation-graph-clip-${i}`)) {
          let canvas = await html2canvas(document.querySelector(`#relation-graph-clip-${i}`))
          base64ImgTreeGraphClip = `<img width="900" height="${
            (canvas.height / canvas.width) * 900
          }" src="${canvas.toDataURL('image/png')}" />`
          clip.graphClip = base64ImgTreeGraphClip
          canvas = await html2canvas(document.querySelector(`#relation-graph-clip-after-${i}`))
          base64ImgTreeGraphClipAfter = `<img width="900" height="${
            (canvas.height / canvas.width) * 900
          }" src="${canvas.toDataURL('image/png')}" />`
          clip.graphClipAfter = base64ImgTreeGraphClipAfter
          // 功能剪裁表数据
          clip.selected.forEach((select) => {
            select.component = this.triz.relationGraphData.nodes.find((node) => node.id === select.id).text
            select.children = this.triz.relationGraphData.links
              .filter((relation) => !relation.isHide)
              .filter((relation) => relation.from == select.id)
              .map((relation) => {
                relation.newText = this.triz.relationGraphData.nodes.find((node) => node.id === relation.new)?.text
                return relation
              })
            select.rowspan = select.children.length
          })
        }
      }

      // S曲线图
      // let base64ImgCurve
      // if (document.querySelector('#s-curve')) {
      //   let canvas = await html2canvas(document.querySelector('#s-curve'))
      //   base64ImgCurve = `<img width="900" height="${(canvas.height / canvas.width) * 900}" src="${canvas.toDataURL(
      //     'image/png'
      //   )}" />`
      // }

      // // 八大进化公式图
      // let base64ImgExpression
      // if (document.querySelector('#evaluation-expression')) {
      //   let canvas = await html2canvas(document.querySelector('#evaluation-expression'))
      //   base64ImgExpression = `<img width="900" height="${
      //     (canvas.height / canvas.width) * 900
      //   }" src="${canvas.toDataURL('image/png')}" />`
      // }

      // 整理技术矛盾数据
      this.exportLabel = '正在生成Altshuller查表图...'
      for (let i = 0; i < triz.keyProblems.length; i++) {
        const keyProblem = triz.keyProblems[i]
        for (let j = 0; j < keyProblem.conflicts.length; j++) {
          const conflict = keyProblem.conflicts[j]
          for (let k = 0; k < conflict.technicalConflicts.length; k++) {
            const technical = conflict.technicalConflicts[k]
            const allGood = technical.good?.map((good) => parameters.find((ele) => ele.value == good)?.text)?.join(',')
            const allBad = technical.bad?.map((bad) => parameters.find((ele) => ele.value == bad)?.text)?.join(',')
            technical.allGood = allGood ? allGood : ''
            technical.allBad = allBad ? allBad : ''

            let base64ImgAltshuller
            if (document.querySelector(`#altshuller-martrix-${conflict.id}`)) {
              let canvas = await html2canvas(document.querySelector(`#altshuller-martrix-${conflict.id}`))
              base64ImgAltshuller = `<img width="900" height="${
                (canvas.height / canvas.width) * 900
              }" src="${canvas.toDataURL('image/png')}" />`
            }
            conflict.base64ImgAltshuller = base64ImgAltshuller
            conflict.selectedConflictNumber = conflict.selectedConflict + 1
          }
          for (let k = 0; k < conflict.physicalConflicts.length; k++) {
            const physical = conflict.physicalConflicts[i]
            if (!physical) {
              continue
            }

            const conflict1 = conflict.technicalConflicts[0]
            const conflict2 = conflict.technicalConflicts[1]
            physical.keyProbText = `应该${conflict1.c_if}因为${conflict1.c_then}, 应该${conflict2.c_if}因为${conflict2.c_then}`

            for (let l = 0; l < physical.keyWords.length; l++) {
              const keyWord = physical.keyWords[l]
              const word = keyWords.find((ele) => ele.value === keyWord.keyWord)
              keyWord.keyWordDescription = word ? word.resolve : ''
            }
            physical.solutions.forEach((solution) => {
              const selectedPrinciple = principles.find((ele) => ele.value === solution.selectedPrinciple)
              if (selectedPrinciple) {
                solution.selectedPrinciple = `${selectedPrinciple.value} - ${selectedPrinciple.text}`
              }
            })
          }
        }
      }

      // 全部技术方案
      const allSolutions = [
        ...this.triz.clipResolves,
        ...(triz.keyProblems?.flatMap((ele) => ele?.conflicts)?.flatMap((ele) => ele?.solutions) ?? []),
        ...(triz.keyProblems
          ?.flatMap((ele) => ele?.conflicts)
          ?.flatMap((ele) => ele?.physicalConflicts)
          ?.flatMap((ele) => ele?.solutions) ?? []),
        ...(triz.standardResolves?.flatMap((ele) => ele.solutions) ?? []),
        ...(triz.searches?.flatMap((ele) => ele.solutions) ?? []),
      ]

      this.exportLabel = '正在生成最终方案内容...'
      // 最终选择方案
      const finalSelectedSolutions = triz.solutionScores
        .filter((ele) => {
          return allSolutions?.some((solution) => solution.id == ele.id)
        })
        .filter((ele) => ele.checked)
        .map(
          (score) =>
            `方案${
              triz.solutionScores
                .filter((ele) => {
                  return allSolutions?.some((solution) => solution.id == ele.id)
                })
                .findIndex((ele) => ele === score) + 1
            }`
        )
        .join('，')

      const exportData = {
        ...triz,
        systemBound: {
          comp: triz.relationGraphData.nodes.filter((node) => node.data.comp),
          super: triz.relationGraphData.nodes.filter((node) => node.data.super),
        },
        functionTable: triz.relationGraphData.nodes.map((node) => {
          node.children = triz.relationGraphData.links
            .filter((ele) => ele.from === node.id)
            .filter((ele) => !ele.isHide)
            .map((ele, index, arr) => {
				
              const first = index === 0
              const target = triz.relationGraphData.nodes.find((node) => node.id === ele.to)?.text
			  const excution = ele.lineShape == 10 ?'':lineShapesText[ele.lineShape]
              const newEle = {
                ...ele,
                target,
				excution,
                rowspan: first ? arr.length : 0,
                allScore: arr.map((ele) => ele.score || 0).reduce((a, b) => a + b, 0),
                first
              }
              console.log('functionTable', newEle)
              return newEle
            })
          // node.children.unshift(node.text)
          return node
        }),
        issueTable: triz.relationGraphData.links
          .filter((relation) => !relation.isHide)
          .filter(
            (relation) =>
              relation.lineShape == lineShapes.bad ||
              relation.lineShape == lineShapes.unsatisfy ||
              relation.lineShape == lineShapes.over
          )
          .map((relation) => ({
            issue: `${triz.relationGraphData.nodes.find((node) => node.id == relation.from).text}${relation.text}${
              triz.relationGraphData.nodes.find((node) => node.id == relation.to).text
            }功能${lineShapesText[relation.lineShape]}`,
            detail: relation.issue,
          })),
        allProblems: this.getAllProblems(),
        solutionScores: triz.solutionScores
          .filter((ele) => {
            return allSolutions?.some((solution) => solution.id == ele.id)
          })
          .map((ele) => {
            const solution = allSolutions?.find((solution) => solution.id == ele.id)
            ele.solutionDetail = solution?.solutionDetail?.replace(/<[^>]+>/g, '') //.substring(0, 50) + '......'
            ele.average = this.average(ele)
            return ele
          }),
        finalSelectedSolutions,
        standardResolves: await Promise.all(
          this.triz.standardResolves.map(async (resolve, i) => {
            if (document.querySelector(`#standard-resolve-model2-img-${i}`)) {
              let canvas = await html2canvas(document.querySelector(`#standard-resolve-model2-img-${i}`))
              resolve.problemImg = `<img width="900" height="${
                (canvas.height / canvas.width) * 900
              }" src="${canvas.toDataURL('image/png')}" />`
            }
            if (document.querySelector(`#standard-resolve-model5-img-${i}`)) {
              let canvas = await html2canvas(document.querySelector(`#standard-resolve-model5-img-${i}`))
              resolve.resolveImg = `<img width="900" height="${
                (canvas.height / canvas.width) * 900
              }" src="${canvas.toDataURL('image/png')}" />`
            }
            resolve.labels = obj_field_level_5
              .find((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == resolve.model5)
              ?.labels?.map((label) => `${label}: ${resolve[label]}`)
            resolve.model1Text = obj_field_level_1.find((ele) => ele.main == resolve.model3)?.text
            resolve.model2Text = obj_field_level_2.find((ele) => ele.main == resolve.model3)?.text
            resolve.model3Text = obj_field_level_3.find((ele) => ele.main == resolve.model3)?.text
            resolve.model4Text = obj_field_level_4.find(
              (ele) => ele.main == resolve.model4.split('.')[0] && ele.sub == resolve.model4.split('.')[1]
            )?.text
            resolve.model5Text = obj_field_level_5.find(
              (ele) =>
                ele.main == resolve.model5.split('.')[0] &&
                ele.sub == resolve.model5.split('.')[1] &&
                ele.tail == resolve.model5.split('.')[2]
            )?.text
            return resolve
          })
        ),
        // base64ImgCurve: base64ImgCurve,
        // base64ImgExpression: base64ImgExpression,
        // nineScreenGraph: base64ImgNine,
        base64ImgRoad: base64ImgRoad,
        reasonTree: base64ImgTree,
        base64ImgSystemComponents: base64ImgSystemComponents,
        relationTable: base64ImgRelationTable,
        graphModel: base64ImgGraphModel,
      }
      // console.log('exportData', exportData)
      return exportData
    },
    getAllProblems() {
      let i = 1
      // 功能分析
      const nodes = this.triz.relationGraphData.nodes ?? []
      const relations =
        this.triz.relationGraphData.links
          ?.filter((relation) => !relation.isHide)
          ?.filter(
            (relation) =>
              relation.lineShape == lineShapes.bad ||
              relation.lineShape == lineShapes.unsatisfy ||
              relation.lineShape == lineShapes.over
          ) ?? []
      const functionProblems = relations?.map((relation) => {
        const result = {}
        result.number = i
        i++
        result.tool = '功能分析'
        result.keyIssue = `${nodes.find((node) => node.id == relation.from).text}${relation.text}${
          nodes.find((node) => node.id == relation.to).text
        }功能${lineShapesText[relation.lineShape]}`
        result.keyProblem = relation.issue
        result.keyConflict = relation.desc
        return result
      })

      // 因果链分析
      const reasonProblems =
        this.flatTreeData(this.triz.reasonTreeData)?.map((reason) => {
          const result = {}
          result.number = i
          i++
          result.tool = '因果链分析'
          result.keyIssue = reason.label
          result.keyProblem = reason.problem
          result.keyConflict = reason.desc
          return result
        }) ?? []

      const clipProblems =
        this.triz.clips?.flatMap((clip) =>
          clip.selected?.flatMap((select) => {
            const relations = this.triz.relationGraphData.links
              .filter((relation) => !relation.isHide)
              .filter((relation) => relation.from == select.id)
            return relations?.flatMap((relation) => {
              const result = {}
              result.number = i
              i++
              result.tool = '功能裁剪'
              result.keyIssue = relation.text
              result.keyProblem = relation.problem
              result.keyConflict = relation.clipDesc
              return result
            })
          })
        ) ?? []

      return [...functionProblems, ...reasonProblems, ...clipProblems]
    },
    async onExit() {
      this.foundedData = null
      const ok = await this.$dialog.confirm({
        text: '您确定要退出吗？',
        title: '请确认',
        actions: {
          false: '不',
          true: '是的',
        },
      })
      if (!ok) return
      if (this.dirty) {
        const shouldSave = await this.$dialog.confirm({
          text: '您有未保存内容，需要保存吗？',
          title: '请确认',
          actions: {
            false: '不保存',
            true: '好的',
          },
        })
        if (shouldSave) {
          await this.onSaveClick()
        }
      }
      this.$store.commit('resetTriz')
      if (this.review) {
        this.$router.push('/my/review')
      } else {
        this.$router.push('/my/project')
      }
    },
    onUndoClick() {
      this.$store.commit('undo')
    },
    onRedoClick() {
      this.$store.commit('redo')
    },
    async onCheckClick(validate) {
      const result = await validate()
      this.fields = result.fields
      this.$store.commit('drawerRight', true)
      this.tab = 'tab-2'
    },
    helpToField(target) {
      console.log('target', target)
      this.$vuetify.goTo(`#${target}`, {
        duration: 1000,
        offset: 100,
        easing: 'easeInOutCubic',
      })
    },
    jumpToField(target) {
      console.log('target', target)
      this.$vuetify.goTo(`#${target.target ? target.target : target}`, {
        duration: 1000,
        offset: 100,
        easing: 'easeInOutCubic',
      })
    },
    changeRecognizeStep(selected, sub) {
      selected ? this.addRecognizeStep(sub) : this.removeRecognizeStep(sub)
    },
    isRecognizeStepSelected(sub) {
      return this.triz.selectedRecognizeSteps.some((step) => step.id === sub.id)
    },
    isResolveStepSelected(sub) {
      return this.triz.selectedResolveSteps.some((step) => step.id === sub.id)
    },
    changeResolveStep(selected, sub) {
      selected ? this.addResolveStep(sub) : this.removeResolveStep(sub)
    },
    addRecognizeStep(sub) {
      this.triz.selectedRecognizeSteps.push(sub)
      this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
      this.$store.commit('updateTriz', this.triz)
      this.$store.commit('do', this.triz)
    },
    removeRecognizeStep(sub) {
      const step = this.triz.selectedRecognizeSteps.find((ele) => ele.id === sub.id)
      if (step) {
        const index = this.triz.selectedRecognizeSteps.findIndex((ele) => ele.id === sub.id)
        this.triz.selectedRecognizeSteps.splice(index, 1)
        this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
        this.$store.commit('updateTriz', this.triz)
        this.$store.commit('do', this.triz)
      }
    },
    addResolveStep(sub) {
      this.triz.selectedResolveSteps.push(sub)
      this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
      this.$store.commit('updateTriz', this.triz)
      this.$store.commit('do', this.triz)
    },
    removeResolveStep(sub) {
      const step = this.triz.selectedResolveSteps.find((ele) => ele.id === sub.id)
      if (step) {
        const index = this.triz.selectedResolveSteps.findIndex((ele) => ele.id === sub.id)
        this.triz.selectedResolveSteps.splice(index, 1)
        this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
        this.$store.commit('updateTriz', this.triz)
        this.$store.commit('do', this.triz)
      }
    },
    computeStepNumber(main, sub, index) {
      if (!this.locked) {
        // if (main.id === 2) {
        //   if (!this.triz.selectedRecognizeSteps.some((step) => step.id === sub.id)) {
        //     return ''
        //   }
        //   return `${main.id}-${this.triz.selectedRecognizeSteps.findIndex((step) => step.id === sub.id) + 1}`
        // }
        // if (main.id === 3) {
        //   if (!this.triz.selectedResolveSteps.some((step) => step.id === sub.id)) {
        //     return ''
        //   }
        //   return `${main.id}-${this.triz.selectedResolveSteps.findIndex((step) => step.id === sub.id) + 1}`
        // }
      }
      return `${main.id}-${index + 1}`
    },
    computeTailNumber(main, sub, tail, index) {
      if (!this.locked) {
        return `${main.id}-${sub.id}-${index + 1}`
      }
      return `${main.id}-${sub.id}-${index + 1}`
    },
    flatTreeData(tree) {
      const result = []
      if (!tree.children) return
      this.flatTree(tree.children, result)
      return result.filter((ele) => ele.selected)
    },
    flatTree(children, result) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        result.push(child)
        if (child.children) {
          this.flatTree(child.children, result)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.left_triz_display {
  .v-stepper__label {
    position: relative;
    display: flex;
  }
}
.v-stepper__step__step {
  line-height: 36px;
  width: 36px;
  height: 36px;
  min-width: 36px;
}
.children_Content {
  .v-stepper--vertical {
    padding-bottom: 8px;
  }
}
.left_triz_displayChildren {
  .v-stepper__label {
    position: relative;
    display: flex;
  }
  .v-stepper__step__step {
    width: 36px;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
  }
}
.delete_tool {
  width: 20px !important;
  min-width: 20px !important;
  border: none;
  position: absolute;
  right: 0;
  border-radius: 20px;
  margin-top: -5px !important;
  .v-icon--left {
    margin: 0 !important;
  }
}
.bj_left_min_height {
  min-height: 100%;
}
.tab_topStop {
  position: sticky;
  top: 0;
  z-index: 999;
}
.bj_right_app .v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.bj_left_app .v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.gztitle {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
</style>
