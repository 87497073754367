<template>
  <div style="font-size: 24px">
    <v-row id="nine-graph" class="d-flex flex-column">
      <v-col align="center" justify="center" style="padding: 12px 12px 0">
        <div class="nineDiv" style="width: 620px; height: 580px">
          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">超系统的过去</div>
            <triz-validator rules="required" id="九屏分析-超系统的过去" class="nieFX">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sup.back"
                @input="change"
              ></triz-textarea>
            </triz-validator>
          </div>
          <div class="nineline" style="width: 11%; height: 25%">
            <div class="leftLine"></div>
            <div class="leftxLine"></div>
          </div>
          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">超系统的现在</div>
            <triz-validator rules="required" id="九屏分析-超系统的现在">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sup.now"
                @input="change"
              />
            </triz-validator>
          </div>
          <div class="nineline" style="width: 11%; height: 25%">
            <div class="rightxLine"></div>
            <div class="rightLine"></div>
          </div>
          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">超系统的未来</div>
            <triz-validator rules="required" id="九屏分析-超系统的未来">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sup.future"
                @input="change"
              />
            </triz-validator>
          </div>

          <div style="float: left; width: 100%; height: 45%">
            <div class="ninetbline">
              <div class="topLine"></div>
              <div class="topxLine"></div>
            </div>
            <div style="width: 100%; height: 60%">
              <div class="nineContent2" style="width: 26%; height: 100%">
                <div class="contentTitle">当前系统的过去</div>
                <triz-validator rules="required" id="九屏分析-当前系统的过去">
                  <triz-textarea
                    class="triz-textarea"
                    placeholder="请输入内容"
                    :max="50"
                    v-model="triz.nineScreenGraph.own.back"
                    @input="change"
                  />
                </triz-validator>
              </div>
              <div class="nineline" style="width: 11%; height: 100%">
                <div class="leftLine"></div>
                <div class="leftxLine"></div>
              </div>
              <div class="nineContent2" style="width: 26%; height: 100%">
                <div class="contentTitle">当前系统的现在</div>
                <triz-validator rules="required" id="九屏分析-当前系统的现在">
                  <triz-textarea
                    class="triz-textarea"
                    placeholder="请输入内容"
                    :max="50"
                    v-model="triz.nineScreenGraph.own.now"
                    @input="change"
                  />
                </triz-validator>
              </div>
              <div class="nineline" style="width: 11%; height: 100%">
                <div class="rightxLine"></div>
                <div class="rightLine"></div>
              </div>
              <div class="nineContent2" style="width: 26%; height: 100%">
                <div class="contentTitle">当前系统的未来</div>
                <triz-validator rules="required" id="九屏分析-当前系统的未来">
                  <triz-textarea
                    class="triz-textarea"
                    placeholder="请输入内容"
                    :max="50"
                    v-model="triz.nineScreenGraph.own.future"
                    @input="change"
                  />
                </triz-validator>
              </div>
            </div>
            <div class="ninetbline">
              <div class="bottomxLine"></div>
              <div class="bottomLine"></div>
            </div>
          </div>

          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">子系统的过去</div>
            <triz-validator rules="required" id="九屏分析-子系统的过去">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sub.back"
                @input="change"
              />
            </triz-validator>
          </div>
          <div class="nineline" style="width: 11%; height: 25%">
            <div class="leftLine"></div>
            <div class="leftxLine"></div>
          </div>
          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">子系统的现在</div>
            <triz-validator rules="required" id="九屏分析-子系统的现在">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sub.now"
                @input="change"
              />
            </triz-validator>
          </div>
          <div class="nineline" style="width: 11%; height: 25%">
            <div class="rightxLine"></div>
            <div class="rightLine"></div>
          </div>
          <div class="nineContent" style="width: 26%; height: 25%">
            <div class="contentTitle">子系统的未来</div>
            <triz-validator rules="required" id="九屏分析-子系统的未来">
              <triz-textarea
                class="triz-textarea"
                placeholder="请输入内容"
                :max="50"
                v-model="triz.nineScreenGraph.sub.future"
                @input="change"
              />
            </triz-validator>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div align="center" justify="center">系统的九屏分析图</div>
      </v-col>
    </v-row>
    <triz-validator rules="required" id="九屏分析-过去的问题">
      <Tinymce v-model="triz.nineScreenProblem.back" @input="change" placeholder="请输入过去的问题" />
    </triz-validator>
    <triz-validator rules="required" id="九屏分析-现在的问题">
      <Tinymce v-model="triz.nineScreenProblem.now" @input="change" placeholder="请输入现在的问题" />
    </triz-validator>
    <triz-validator rules="required" id="九屏分析-未来的问题">
      <Tinymce v-model="triz.nineScreenProblem.future" @input="change" placeholder="请输入未来的问题" />
    </triz-validator>

    <p>
      而我们基于TRIZ理论所发明制作的
      <triz-validator rules="required" id="九屏分析-文本框1">
        <InnerTextField v-model="triz.nineScreenSlots.slot01" @input="change" />
      </triz-validator>
      系统， 不仅解决了传统
      <triz-validator rules="required" id="九屏分析-文本框2">
        <InnerTextField v-model="triz.nineScreenSlots.slot02" @input="change" />
      </triz-validator>
      的问题， 并且能够有效地解决了
      <triz-validator rules="required" id="九屏分析-文本框3">
        <InnerTextField v-model="triz.nineScreenSlots.slot03" @input="change" />
      </triz-validator>
      的问题， 具有
      <triz-validator rules="required" id="九屏分析-文本框4">
        <InnerTextField v-model="triz.nineScreenSlots.slot04" @input="change" />
      </triz-validator>
      的优点，预计应用价值较高，市场前景广阔。
    </p>
  <template v-for="(resolve, i) in triz.nineResolves">
      <div :key="`resolve-${i}`">
        <v-row no-gutters>
          解决方案{{ i + 1 }}：
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="red" @click="removeNine(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <triz-validator rules="required" id="最终理想解-解决方案">
          <Tinymce v-model="resolve.solutionDetail" @input="change" placeholder="请输入解决方案" />
        </triz-validator>
      </div>
    </template>
    <p>
      <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addNine">
        <v-icon left>mdi-plus</v-icon> 添加解决方案
      </v-btn>
    </p>
  </div>
</template>

<script>
import InnerTextField from '@/components/InnerTextField'
import Tinymce from '@/components/Tinymce'

export default {
  components: {
    InnerTextField,
    Tinymce,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // // triz: this.triz,
    }
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    addNine() {
      const id = `physical-${new Date().getTime()}`
      this.triz.nineResolves.push({
        id,
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeNine(i) {
      const [{ id }] = this.triz.ninelResolves.splice(i, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
  },
}
</script>
<style lang="scss">
.nineDiv {
  .nineContent {
    float: left;
    border: 2px solid #333;
    line-height: 16px;
    .contentTitle {
      font-size: 18px;
      font-weight: bold;
      color: #e73535e5;
      width: 100%;
      height: 20px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .v-input__slot:before {
      border-color: none !important;
    }
    .triz-textarea textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 16px;
      height: 107px !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
    .triz-textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 1px;
      max-height: calc(100% - 24px) !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
  }
  .nineContent2 {
    float: left;
    border: 2px solid #333;
    line-height: 16px;
    .contentTitle {
      font-size: 18px;
      font-weight: bold;
      color: #e73535e5;
      width: 100%;
      height: 20px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
    }
    .v-input__slot:before {
      border-color: none !important;
    }
    .triz-textarea textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 16px;
      height: 119px !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
    .triz-textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 1px;
      max-height: calc(100% - 24px) !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
  }
  .nineline {
    float: left;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }
  .leftLine {
    float: left;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent #0099cc transparent transparent;
  }
  .leftxLine {
    float: right;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .rightLine {
    float: right;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent #0099cc;
  }
  .rightxLine {
    float: left;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .ninetbline {
    width: 30px;
    margin: 0 auto;
    .topLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: transparent transparent #0099cc transparent;
    }
    .topxLine {
      height: 16px;
      position: relative;
      left: 0px;
      width: 15px;
      background: #0099cc;
    }
    .bottomLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: #0099cc transparent transparent transparent;
    }
    .bottomxLine {
      height: 16px;
      position: relative;
      left: 0px;
      width: 15px;
      background: #0099cc;
    }
  }
}
</style>
