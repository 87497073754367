export const TRIZ_STEPS = [
  {
    id: 1,
    title: '项目描述',
    children: [
      {
        id: 1,
        title: '项目背景',
        component: 'Description',
      },
      {
        id: 2,
        title: '问题描述',
        children: [
          {
            id: 1,
            title: '初始情况描述',
            component: 'BackgroudInit',
          },
          {
            id: 2,
            title: '现有系统工作原理',
            component: 'BackgroudCurrent',
          },
          {
            id: 3,
            title: '系统目标',
            component: 'BackgroudTarget',
          },
          {
            id: 4,
            title: '商业目标',
            component: 'BackgroudBusiness',
          },
          {
            id: 5,
            title: '可量化的项目目标',
            component: 'BackgroudCalculate',
          },
          {
            id: 6,
            title: '项目的限制（约束）',
            component: 'BackgroudLimit',
          },
          {
            id: 7,
            title: '项目的更改（允许）',
            component: 'BackgroudAllow',
          },
        ],
      },
      {
        id: 3,
        title: '技术路线',
        component: 'TechRoad',
      },
    ],
  },
  {
    id: 2,
    title: '问题识别',
    children: [
      {
        id: 1,
        title: '功能分析',
        children: [
          {
            id: 1,
            title: '组件分析',
            component: 'FunctionalComponent',
          },
          {
            id: 2,
            title: '相互作用分析',
            component: 'FunctionalEffect',
          },
          {
            id: 2,
            title: '功能建模',
            component: 'FunctionalModel',
          },
        ],
      },
      {
        id: 2,
        title: '因果链分析',
        component: 'Reason',
      },
      {
        id: 3,
        title: '功能裁剪',
        component: 'Clip',
      },
      // {
      //   id: 5,
      //   title: 'S曲线进化法则',
      //   component: 'SCurve',
      // },
      // {
      //   id: 6,
      //   title: '八大进化法则',
      //   component: 'Evaluation',
      // },
    ],
  },
  {
    id: 3,
    title: '问题汇总',
    children: [
      {
        id: 1,
        title: '分析问题列表（总表）',
        component: 'ProblemAnalysis',
      },
    ],
  },
  {
    id: 4,
    title: '问题解决',
    children: [
      {
        id: 1,
        title: '解决矛盾的创新原理或技术',
        component: 'KeyProblem',
      },
      {
        id: 2,
        title: '功能导向搜索（FOS）',
        component: 'GuideSearch',
      },
      {
        id: 3,
        title: '标准解',
        component: 'StandardResovle',
      },
      // {
      //   id: 4,
      //   title: '最终理想解IFR',
      //   component: 'FinalResolve',
      // },
      // {
      //   id: 5,
      //   title: '资源分析',
      //   component: 'Resource',
      // },
      // {
      //   id: 6,
      //   title: '技术矛盾',
      //   component: 'Technical',
      // },
      // {
      //   id: 7,
      //   title: '物理矛盾',
      //   component: 'Physical',
      // },
      // {
      //   id: 8,
      //   title: '九屏分析',
      //   component: 'Nine',
      // },
    ],
  },
  {
    id: 5,
    title: '技术方案整理与评价',
    children: [
      {
        id: 1,
        title: '全部技术方案及评价',
        component: 'Judgement',
      },
      {
        id: 2,
        title: '最终确定方案',
        component: 'Solution',
      },
      {
        id: 3,
        title: '试验验证',
        component: 'Experiment',
      },
    ],
  },
  {
    id: 6,
    title: '成果展示',
    children: [
      {
        id: 1,
        title: '论文专利',
        component: 'Result',
      },
    ],
  },
]
