<template>
  <v-container style="font-size: 24px">
    <v-row align="center" justify="center">
      <JudgeTable
        :all="all"
        v-model="triz.solutionScores"
        @change="change"
      />
    </v-row>
    <v-row align="center" justify="center"> 表 全部技术方案及评价 </v-row>
    <v-row class="special_p">
      <p>
        根据综合评分，我们选择使用
        <template v-for="score in triz.solutionScores.filter((ele) => ele.checked)">
          {{ `方案${triz.solutionScores.filter(ele => {
          return all?.some(solution => solution.id == ele.id)
        }).findIndex((ele) => ele === score) + 1}，` }}
        </template>
        综合多方案设计了一种
        {{ triz.system }}
        系统。
      </p>
    </v-row>
  </v-container>
</template>

<script>
import JudgeTable from '@/components/JudgeTable'

export default {
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {}
  },
  components: {
    JudgeTable,
  },
  mounted() {},
  computed:{
    all() {
      return [
          ...this.triz.clipResolves,
          //...triz.idealResolves,
          //...triz.nineResolves,
          ...(this.triz.keyProblems?.flatMap((ele) => ele?.conflicts)?.flatMap((ele) => ele?.solutions) ?? []),
          ...(this.triz.keyProblems
            ?.flatMap((ele) => ele?.conflicts)
            ?.flatMap((ele) => ele?.physicalConflicts)
            ?.flatMap((ele) => ele?.solutions) ?? []),
          ...(this.triz.standardResolves?.flatMap((ele) => ele.solutions) ?? []),
          ...(this.triz.searches?.flatMap((ele) => ele.solutions) ?? []),
        ]
    }
  },
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
