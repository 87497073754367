<template>
  <div style="font-size: 24px">
    <triz-validator rules="required" id="项目概述">
      <Tinymce @input="change" v-model="triz.backgroud.current" placeholder="可以通过系统工作原理模型图或工程流程图来展示系统工作原理" />
    </triz-validator>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'

export default {
  components: {
    Tinymce,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
